<template>
  <div class="content">
    <div class="title">
      <p>{{ $t('Cart.title') }}</p>
      <div class="explain-box" @click="handleFindExplain">
        <van-icon name="warning-o" color="#fff" />
        <p>{{ $t('Cart.explain') }}</p>
      </div>
    </div>
    <div class="table">
      <div
        v-for="(item, index) in tableList"
        :key="index"
        class="space-text"
        :class="{ on: tabelKey === item.key }"
        @click="handleTable(item)"
      >
        {{ item.label }}
      </div>
    </div>
    <div class="money-info">
      <div v-for="(item, index) in moneyInfoList" :key="index">
        <span class="space-text">{{ item.label }}</span>
        <p>{{ item.value }}</p>
      </div>
    </div>
    <div class="wrap">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="$t('Hint.notMore')"
        :loading-text="$t('Hint.loading')"
        @load="getGoodsList"
      >
        <div class="goods" v-for="(item, index) in newList" :key="index">
          <div>
            <div class="title">
              <div class="left">
                <img
                  :src="
                    item.nub_icon
                      ? item.nub_icon
                      : 'https://img.yzcdn.cn/vant/cat.jpeg'
                  "
                />
                <span class="ellipsis">
                  <van-notice-bar
                    color="#d45555"
                    background="#fff"
                    scrollable
                    :text="item.nub ? item.nub : item.desc"
                    :speed="30"
                    :delay="1"
                  />
                </span>
              </div>
              <div class="right">
                <span>{{ $t('Cart.text.rebateAndPrincipal') }}:</span>
                <!-- <p class="ellipsis">Rp{{ item.newAllMoney }}</p> -->
                <p class="ellipsis"> {{ item.newAllMoney }}</p>
              </div>
            </div>
            <div class="cont">
              <img :src="item.icon" />
              <div>
                <div class="top-title">
                  <p class="ellipsis">{{ item.name }}</p>
                  <!-- <van-count-down
                    :time="item.newTime2"
                    format="HH h mm m ss s"
                  /> -->
                  <van-count-down
                    :time="item.newTime2"
                    v-if="item.status || item.showCountDown"
                  >
                    <template #default="timeData">
                      <span class="block">{{ timeData.hours }}</span>
                      <span class="colon">:</span>
                      <span class="block">{{ timeData.minutes }}</span>
                      <span class="colon">:</span>
                      <span class="block">{{ timeData.seconds }}</span>
                    </template>
                  </van-count-down>
                </div>
                <div>
                  <!-- <p>Rp{{ item.newPrice }}</p> -->
                  <p> {{ item.newPrice }}</p>
                  <div>
                    <div>
                      <p>
                        {{ $t('Cart.text.residue') }}:{{
                          item.num - item.curr_num
                        }}{{ $t('Cart.text.one') }}
                      </p>
                    </div>
                    <!-- <button>
                      {{ $t('Cart.text.toGet') }}:{{ item.reward }}
                    </button> -->
                  </div>
                </div>
                <button>{{ $t('Cart.text.toGet') }}:{{ item.reward }}</button>
              </div>
            </div>
            <div class="vantActive">
              <!-- <van-steps :active="item.active">
                <van-step v-for="(e, i) in stepList" :key="i">{{ e }}</van-step>
              </van-steps> -->
              <StepsAuto :active="item.active" :content="stepList" />
            </div>
          </div>
        </div>
      </van-list>
    </div>
    <van-popup v-model="popupShow">
      <div class="popup">
        <p>{{ $t('Cart.hint.p1') }}</p>
        <p>{{ $t('Cart.hint.p2') }}</p>
        <p>{{ $t('Cart.hint.p3') }}</p>
      </div>
    </van-popup>
    <!-- <Tabbar active="order" /> -->
  </div>
</template> 

<script>
import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Toast,
  Icon,
  List,
  Cell,
  Step,
  Steps,
  Popup,
  NoticeBar,
  CountDown,
} from 'vant'
import Tabbar from '@/components/Tabbar'
import StepsAuto from '@/components/StepsAuto'

import { REQUEST_API } from '@/http/api'
import { countTime, getMyTime, deciTurnMoney } from '@/utils/tools'

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [Step.name]: Step,
    [Steps.name]: Steps,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [CountDown.name]: CountDown,
    Tabbar,
    StepsAuto,
  },

  data() {
    return {
      tableList: [
        {
          label: `${this.$t('Cart.state.all')}`,
          key: 'all',
        },
        {
          label: `${this.$t('Cart.state.going')}`,
          key: 'going',
        },
        {
          label: `${this.$t('Cart.state.over')}`,
          key: 'over',
        },
      ],
      tabelKey: 'all',
      moneyInfoList: [
        {
          label: `${this.$t('Cart.info.allMoney')}`,
          key: 'allMoney',
          value: 0,
        },
        {
          label: `${this.$t('Cart.info.dayAllOrder')}`,
          key: 'dayAllOrder',
          value: 0,
        },
        {
          label: `${this.$t('Cart.info.dayTrustMoney')}`,
          key: 'dayTrustMoney',
          value: 0,
        },
        {
          label: `${this.$t('Cart.info.yesterdayMoney')}`,
          key: 'yesterdayMoney',
          value: 0,
        },
      ],
      list: [],
      loading: false,
      finished: false,
      active: 1,
      stepList: [
        `${this.$t('Cart.text.orderSuccess')}`,
        `${this.$t('Cart.text.orderGetMoney')}`,
        `${this.$t('Cart.text.orderWaiting')}`,
        `${this.$t('Cart.text.orderOver')}`,
        `${this.$t('Cart.text.rebateAndPrincipal')}`,
      ],
      userInfo: [],
      pageNo: 1,
      level: 1,
      state: 0,
      popupShow: false,
    }
  },

  computed: {
    // submitBarText() {
    //   const count = this.checkedGoods.length;
    //   return '结算' + (count ? `(${count})` : '');
    // },

    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      )
    },
    newList() {
      let time = new Date().valueOf()
      let list = this.list.map((_) => ({
        ..._,
        active: _.state === 2 ? 4 : _.state === 1 && _.end_time < time ? 2 : 3,
        // newPrice: _.price > 99999 ? `${_.price * 0.001}k` : _.price,
        newPrice: deciTurnMoney(_.price),
        // newAllMoney:
        //   _.price + _.reward > 99999
        //     ? `${(_.price + _.reward) * 0.001}k`
        //     : _.price + _.reward,
        newAllMoney: deciTurnMoney(_.price + _.reward),
        // reward: _.reward > 99999 ? `${_.reward * 0.001}k` : _.reward,
        reward: deciTurnMoney(_.reward),
        newTime2: _.end_time * 1000 - Date.parse(new Date()),
        showCountDown:
          _.end_time * 1000 - Date.parse(new Date()) <= 0 ? false : true,
      }))
      return list
    },
  },
  created() {
    this.init()
    this.getMoneyInfo()
  },

  methods: {
    /** handle **/
    handleFindExplain() {
      this.popupShow = true
    },
    handleTable(item) {
      if (item.key === this.tabelKey) {
        return false
      }
      this.tabelKey = item.key
      let list = ['all', 'going', 'over']
      list.forEach((_, i) => {
        if (this.tabelKey === _) {
          this.state = i
          this.list = []
          this.finished = false
          this.pageNo = 1
        }
      })
    },
    handleBuy() {
      // 此页面无此功能
      // console.log('买东西了')
    },
    /** api **/
    getGoodsList() {
      if (this.finished) {
        return false
      }
      let form = {
        page: this.pageNo,
        pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        level: this.userInfo[1].data.player.level,
        state: this.state,
        cmd: '201001',
      }
      REQUEST_API(form)
        .then((r) => {
          console.log(r.data)
          if (!r.data.sc) {
            if (r.data.notifyList[0].data.count) {
              this.list.push(...r.data.notifyList[0].data.data)
              this.finished =
                r.data.notifyList[0].data.page ===
                r.data.notifyList[0].data.pageSize
              this.pageNo++
              if (this.finished) {
                this.loading = false
              }
            } else {
              this.list = []
              this.loading = false
              this.finished = true
            }
          } else {
            Toast(this.$t('Hint.failed'))
            this.list = []
            this.loading = false
            this.finished = true
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    getMoneyInfo() {
      let form = {
        uid: this.userInfo[1].data.player.uid,
        cmd: '201004',
      }
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            let moneyForm = r.data.notifyList[0].data
            this.moneyInfoList[0].value = deciTurnMoney(moneyForm.diamond) || 0
            this.moneyInfoList[1].value = moneyForm.size || 0
            this.moneyInfoList[2].value = deciTurnMoney(moneyForm.price) || 0
            this.moneyInfoList[3].value =
              deciTurnMoney(moneyForm.last_reward) || 0
          } else {
            Toast(this.$t('Hint.failed'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    /** 其他 **/
    init() {
      this.userInfo = JSON.parse(localStorage.getItem('user-info'))
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  height: calc(100vh - (50rem / 16));
  background: #f1f2f3;
  > .title {
    height: calc(49rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffba04;
    color: #464646;
    font-weight: 400;
    font-size: calc(18rem / 16);
    position: relative;
    width: 100%;
    margin-top: 0;
    > .explain-box {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: calc(16rem / 16);
      position: absolute;
      top: 50%;
      right: calc(16rem / 16);
      transform: translate(0, -50%);
      color: #fff;
      > p {
        // padding-bottom: calc(4rem / 16);
        margin-left: calc(3rem / 16);
      }
    }
  }
  > .table {
    display: flex;
    font-size: calc(12rem / 16);
    > div {
      width: 33.33%;
      height: calc(38rem / 16);
      line-height: calc(38rem / 16);
      text-align: center;
      background: #fff;
      &.on {
        color: #ffba04;
      }
    }
  }
  > .money-info {
    width: 98%;
    display: flex;
    align-items: center;
    margin: calc(10rem / 16) auto;
    text-align: center;
    > div {
      width: 25%;
      height: calc(70rem / 16);
      background: #a71b1b;
      font-size: calc(12rem / 16);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      > p {
        font-size: calc(13rem / 16);
        margin-top: calc(10rem / 16);
      }
    }
    > div:nth-child(1) {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: calc(1rem / 16) solid #ab7575;
    }
    > div:nth-child(2) {
      border-right: calc(1rem / 16) solid #ab7575;
    }
    > div:nth-last-child(1) {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: calc(1rem / 16) solid #ab7575;
    }
  }
  > .wrap {
    height: calc(
      100vh - (50rem / 16) - (49rem / 16) - (38rem / 16) - (90rem / 16)
    );
    overflow: hidden;
    overflow-y: auto;
    .goods {
      > div:nth-child(1) {
        width: calc(98% - (30rem / 16));
        // height: calc(145rem / 16);
        margin: auto;
        background: #fff;
        border-radius: 8px;
        margin-bottom: calc(8rem / 16);
        padding: 0 calc(15rem / 16);
        > .title {
          width: 100%;
          height: calc(40rem / 16);
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: calc(1rem / 16) solid #d3cec7;
          > .left {
            // width: 50%;
            display: flex;
            align-items: center;
            line-height: calc(18rem / 16);
            > img {
              width: calc(18rem / 16);
              height: calc(18rem / 16);
              margin-right: calc(7rem / 16);
            }
            > span {
              width: calc(50rem / 16);
              font-size: calc(16rem / 16);
              color: #d45555;
              .van-notice-bar {
                padding: 0;
              }
            }
          }
          > .right {
            // width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-items: flex-end;
            font-size: calc(10rem / 16);
            line-height: calc(10rem / 16);
            line-height: calc(12rem / 16);
            color: #d45555;
            > span {
              font-size: calc(10rem / 16);
              line-height: calc(10rem / 16);
              color: #7c5e5e;
            }
          }
        }
        > .cont {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: calc(10rem / 16) 0 calc(15rem / 16);
          > img {
            width: calc(62rem / 16);
            height: calc(62rem / 16);
          }
          > div {
            width: calc(100% - (68rem / 16));
            // height: calc(62rem / 16);
            // > p {
            //   font-size: calc(14rem / 16);
            //   line-height: calc(14rem / 16);
            //   color: #333;
            // }
            > .top-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row;
              > p,
              > span {
                font-size: calc(14rem / 16);
                line-height: calc(14rem / 16);
                color: #333;
              }
              > p {
                margin-top: 0;
              }
            }
            > div {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              height: calc(100% - (14rem / 16));
              position: relative;
              > p {
                font-size: calc(13rem / 16);
                color: #fe8900;
                margin-top: calc(8rem / 16);
              }
              > div {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                > div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: calc(13rem / 16);
                  color: #fff;
                  > p {
                    background: #b36900;
                    padding: calc(2rem / 16) calc(5rem / 16);
                    margin: calc(8rem / 16) 0;
                    border-radius: 4px;
                  }
                }
                // > button {
                //   color: #fff;
                //   background: #ff9a04;
                //   border-radius: 6px;
                //   border: 0;
                //   font-size: calc(14rem / 16);
                //   padding: calc(7rem / 16) calc(9rem / 16);
                //   position: absolute;
                //   bottom: 0;
                //   right: 0;
                // }
              }
            }
            > button {
              color: #fff;
              background: #ff9a04;
              border-radius: 6px;
              border-radius: 4px;
              border: 0;
              font-size: calc(13rem / 16);
              // padding: calc(7rem / 16) calc(9rem / 16);
              padding: calc(2rem / 16) calc(5rem / 16);
              // position: absolute;
              // bottom: 0;
              // right: 0;
            }
          }
        }
      }
    }
  }
  .popup {
    width: 80vw;
    padding: calc(15rem / 16) calc(20rem / 16);
    font-size: calc(15rem / 16);
    > p {
      margin-bottom: calc(8rem / 16);
    }
  }
  .colon {
    display: inline-block;
    margin: 0 4px;
    color: #b36900;
  }
  .block {
    display: inline-block;
    width: 22px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: #b36900;
  }
}
</style>
